<script setup lang="ts">
import { useError } from '~~/stores/error';
import { useI18n } from 'vue-i18n';
import type { Lang } from '~~/composables/locale';
import type { User } from '~~/stores/user';
import type { RouteRecordName } from 'vue-router';

const {
  $featureFlagInitClient,
  $featureFlagInitFlags,
  $canManageDraft,
  $canManageOrganization,
  $i18n,
  $sentrySetUser,
} = useNuxtApp();

const { t } = useI18n();
const route = useRoute();
const instName = instanceName();
const cookie = useHCookie();
await setCurrentUser(cookie);
setLocale();
const isLoading = ref<boolean>(true);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
$i18n.onBeforeLanguageSwitch = async (oldLocale: Lang): void => (oldLocale);

useHead({
  title: computed(() => {
    let title = t(route.meta.title as string);
    if (instName) title += ' - ' + t(`instance.${instName}.title`);
    return title;
  }),
  meta: [{
    name: 'description', content: computed(() => t(route.meta.description as string)),
  }],
});

// Used to prevent unathorized users from accessing the draft
const isRouteForDraft = (): boolean => {
  const draftRoutes: RouteRecordName[] = [
    'publication-drafts',
    'publication-drafts-new',
    'publication-drafts-id-step',
    'publication-id',
    'publications',
    'collections',
    'contributors',
  ];

  return draftRoutes.includes(route.name);
};

// Used to prevent unathorized users from accessing the organization page
const isRouteForOrganization = (): boolean => {
  const organizationRoutes: RouteRecordName[] = [
    'organizations-id-activities',
    'organizations-id-edit',
  ];

  return organizationRoutes.includes(route.name);
};

/**
 * Check if the user can access the page depending on the route
 * @returns {boolean}
 */
const canAccessPage = (): boolean => {
  if (isRouteForDraft()) {
    return $canManageDraft();
  } else if (isRouteForOrganization()) {
    return $canManageOrganization();
  } else {
    return $canManageDraft(); // all other routes will be redirected to the draft pages for now
  }
};

/**
 * Authorize the user to access the page or redirect to 401
*/
const authorize = (): void => {
  isLoading.value = true;
  if (canAccessPage()) {
    isLoading.value = false;
  } else {
    useRouter().push('/401');
  }
};

let user: undefined | User;
const errorStore = useError();
if (process.client) {
  user = currentUser();
  if (user.exists) {
    const opts = {
      id: user.id,
      email: user.email,
      organization: {
        id: user.organization?.id || null,
        name: user.organization?.name || null,
      },
    };
    $sentrySetUser(opts);
  }

  await $featureFlagInitClient();
  $featureFlagInitFlags();
  authorize();
}

// Ensure the user is authorized to access the page when it changes
watch(() => route.path, authorize);
</script>
<template>
  <div class="l-wrapper">
    <div
      v-if="isLoading"
      class="flex-auto"
    >
      {{ $t('loading') }}
    </div>
    <div
      v-else-if="user"
      class="flex-auto"
    >
      <div>
        <Header />
        <HBreadcrumb />
        <slot />
      </div>
    </div>
    <div
      v-else
      class="flex-auto"
    >
      <slot />
    </div>
    <Footer />
    <div
      v-if="errorStore.active"
      id="error-message"
    >
      <HInfoBox
        id="error-message-content"
        variant="error"
        @close="errorStore.reset"
      >
        <p>{{ errorStore.message }}</p>
      </HInfoBox>
    </div>
  </div>
</template>
